
import { Component } from '@angular/core';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})

export class BannerComponent {

  image: string = 'assets/img/home-banner.jpg';

  constructor() { }

}
