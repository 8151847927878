import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgxMasonryModule } from 'ngx-masonry';
import { CrystalGalleryModule } from 'ngx-crystal-gallery';
import { CookieLawModule } from 'angular2-cookie-law';

import { HomepageComponent } from './pages/homepage/homepage.component';
import { ProjectPageComponent } from './pages/project-page/project-page.component';
import { ContentPageComponent } from './pages/content-page/content-page.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { TileComponent } from './components/tile/tile.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { BannerComponent } from './components/banner/banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';

import { EmailService } from './services/email.service';


const appRoutes: Routes = [
  { path: 'content/cookie-policy', component: ContentPageComponent },
  { path: 'project/:ident', component: ProjectPageComponent },
  { path: ':location', component: HomepageComponent },
  { path: '', component: HomepageComponent },
  { path: '**', component: HomepageComponent }
];


@NgModule({
  declarations: [
    HomepageComponent,
    ProjectPageComponent,
    AppComponent,
    NavbarComponent,
    TileComponent,
    TitleBarComponent,
    BannerComponent,
    FooterComponent,
    ContactFormComponent,
    ContentPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMasonryModule,
    CrystalGalleryModule,
    CookieLawModule,
    ScrollToModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
  ],
  providers: [
    EmailService,
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }
