
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

export interface ContactMessage {
    name: string;
    email: string;
    phone: string;
    subject: string;
    message: string;
    url: string;
}

@Injectable()
export class EmailService {

    // private emailUrl = 'assets/server/email.php';
    private emailUrl = 'assets/mail/mail.php';

    constructor(private http: Http) { }

    sendEmail(message: ContactMessage): Observable<ContactMessage> | any {
        return this.http.post(this.emailUrl, message)
            .pipe(map(response => {
                console.log('Sending email was successfull', response);
                return response;
            }))
            .pipe(catchError(error => {
                console.log('Sending email got error', error);
                return Observable.throw(error);
            }));
    }
}
