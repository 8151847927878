
import { Component } from '@angular/core';
import { EmailService } from './../../services/email.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-contact-form',
    templateUrl: 'contact-form.component.html',
    styleUrls: ['contact-form.component.scss']
})

export class ContactFormComponent {

    loading: boolean = false;
    completed: boolean = false;
    error: boolean = false;

    contactForm: FormGroup;

    name: FormControl;
    email: FormControl;
    message: FormControl;
    humans: FormControl;

    constructor(private emailService: EmailService) {

        this.createFormControls();
        this.createForm();
    }

    sendEmail() {

        this.loading = true;

        this.emailService.sendEmail(this.contactForm.value).subscribe(response => {
            console.log('ContactFormComponent Success', response);
            this.loading = false;
            this.completed = true;
        }, error => {
            console.log('ContactFormComponent Error', error);
            this.loading = false;
            this.error = true;

            setTimeout(() => {
                this.error = false;
            }, 4000);
        });
    }

    // toggleLoading() {
    //     this.loading = !this.loading;
    // }

    createFormControls() {
        this.name    = new FormControl('', [Validators.required, Validators.minLength(3)]);
        this.email   = new FormControl('', [Validators.required, Validators.pattern('[^ @]*@[^ @]*')]);
        this.message = new FormControl('', [Validators.required, Validators.minLength(5)]);
        this.humans  = new FormControl('human');
    }

    createForm() {
        this.contactForm = new FormGroup({
            name: this.name,
            email: this.email,
            message: this.message,
            humans: this.humans
        });
    }

}
