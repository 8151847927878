
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

import { ProjectService } from '../../services/project.service';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})

export class HomepageComponent implements OnInit {

  projects;
  location: string;

  constructor(private scrollToService: ScrollToService,
              private route: ActivatedRoute,
              private projectService: ProjectService) {

    this.location = this.route.snapshot.paramMap.get('location');
  }

  ngOnInit() {
    this.loadProjects();

    setTimeout(() => {
      this.scrollToLocation();
    }, 100);
  }

  loadProjects() {
    this.projectService.loadProjects().subscribe(data => {
      this.projects = data;
    });
  }

  scrollToLocation() {
    const config: ScrollToConfigOptions = {
      target: this.location
    };

    this.scrollToService.scrollTo(config);
  }
}
