
import { Component, Input } from '@angular/core';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent {

  @Input() items;

  shouldStick: boolean = false;
  openMenu: boolean = false;
  scrollMenu: boolean = false;

  constructor() {
    window.addEventListener('scroll', _ => {
      if (window.pageYOffset > 100) {
        this.scrollMenu = true;
      } else {
        this.scrollMenu = false;
      }
    });
  }

  toggleMobileMenu() {
    this.openMenu = !this.openMenu;
  }

}
