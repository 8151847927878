
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/index';


@Injectable({
  providedIn: 'root'
})

export class ProjectService {

  private _projects;

  constructor(private http: HttpClient) { }

  loadProjects() {
    return this.http.get('assets/data/projects.json')
                    .pipe(map(data => {
                      this._projects = data;
                      return data;
                    }));
  }

  getProject(ident) {
    return this.http.get('assets/data/projects.json')
      .pipe(map((data: Array<any>) => {

          const project = data.find(o => o.ident === ident);
          return project;
      }));
  }
}
