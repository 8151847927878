
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxMasonryOptions } from 'ngx-masonry';
import { CrystalLightbox } from 'ngx-crystal-gallery';

import { ProjectService } from '../../services/project.service';


@Component({
  selector: 'app-project-page',
  templateUrl: './project-page.component.html',
  styleUrls: ['./project-page.component.scss']
})

export class ProjectPageComponent {

  public masonryOptions: NgxMasonryOptions = {
    transitionDuration: '0.2s',
    gutter: 20,
    resize: true,
    initLayout: true,
    fitWidth: true
  };

  project;
  ident: string;

  images = [];

  constructor(private route: ActivatedRoute,
              private projectService: ProjectService,
              private lightbox: CrystalLightbox) {

    window.scrollTo(0, 0);

    this.ident = this.route.snapshot.paramMap.get('ident');

    this.getProject();

  }

  getProject() {
    this.projectService.getProject(this.ident).subscribe(data => {
      this.project = data;
      this.images = data.images.Images;
    });
  }

  wopenImage(index: number) {

    const images = this.images.map(image => {
      return {path: image};
    });

    this.lightbox.open(images, {index: index});
  }
}
